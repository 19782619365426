import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCuDD1ZB5T2qD26rzHWDMNNIPbh606dzaU",
  authDomain: "stock-user-724c8.firebaseapp.com",
  projectId: "stock-user-724c8",
  storageBucket: "stock-user-724c8.appspot.com",
  messagingSenderId: "653095905018",
  appId: "1:653095905018:web:aa03ea3def001bd8be0b43",
  measurementId: "G-WXYBKRQ4FP",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export async function registerAndRequestPermission() {
  if (!isSupported()) {
    console.error("Push notifications are not supported in this browser.");
    return null;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.error("Notification permission denied.");
      return false;
    }

    return true;
  } catch (err) {
    console.error(
      "Error registering service worker or requesting permission:",
      err
    );
    return null;
  }
}

export async function getTokenData(registration) {
  if (!registration) {
    console.error("Failed to register service worker before requesting token.");
    return null;
  }
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BDuE_iigQPud6UseU64g8UWFFgtkqV2L4Z3o64hhpFAQhn4mCTYwGr0YRgCjYx4Yg7al2pgo6b01-yqt-Drzsj0",
    });
    return token;
  } catch (err) {
    console.error("Error getting FCM token:", err);
    return null;
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
