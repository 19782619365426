import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Navbar from "./Navbar";
import { postMethod, getMethod } from "../../services/base_service";
import { showAlert } from "../../services/alert_service";
import { Apiurl } from "../../services/apiRoute";

const SendMessage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSendNotification = async (data) => {
    try {
  
      setIsLoading(true);
      const response = await postMethod(Apiurl.send_message, data);
      setIsLoading(false);
      reset();
      showAlert(response.data.message, "success");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getUsers = async () => {
    setIsLoading(true);
    await getMethod(`${Apiurl.user_list}`)
      .then((res) => {
        setTotalPages(res.data.total_page);
        setUsers(res.data.data);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error" + error);
        showAlert("Error while fetching data", "error");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Send Message</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleSendNotification)}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        User
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="user_id"
                          className="form-control"
                          // style={{ marginLeft: "8px" }}
                          {...register("user_id", {
                            required: "User selection is Required.",
                          })}
                        >
                          <option value="" disabled selected>
                            Select user...
                          </option>
                          {users.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.user_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.user_id && (
                      <p
                        style={{
                          color: "red",
                          marginBottom: "0px",
                          fontSize: "13px",
                        }}
                      >
                        {errors.user_id.message}
                      </p>
                    )}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Title
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("title", {
                            required: "Title is Required.",
                          })}
                          className="form-control"
                          name="title"
                          id="inputEmail3"
                          placeholder="Enter Title"
                        />
                        {errors.title && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.title.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Description
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("desc", {
                            required: "Description is Required.",
                          })}
                          className="form-control"
                          name="desc"
                          id="inputEmail2"
                          placeholder="Enter Description"
                        />
                        {errors.desc && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.desc.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
