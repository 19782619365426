import CurrencyRequests from "../components/pages/request/CurrencyRequests";

export const Apiurl = {
  // routePath: "http://localhost:3011/api/v1",
  routePath: "https://apiuserstock.genxel.in/api/v1",

  // imageRoutePath: "http://localhost:3011",
  imageRoutePath:"https://apiuserstock.genxel.in",
  login: "/admin/login",
  t_pc: "/t_pc",
  currency: "/currency",
  currencyUpdate: "/admin/currency/update",
  accountRequest: "/admin/account_request/list",
  passwordRequest: "/admin/password_request/list",
  referralRequest: "/admin/referral_request/list",
  currencyRequest: "/admin/currency_request/list",
  password: "/admin/update/password",
  account: "/admin/account",
  referral: "/admin/update/referral",
  signup: "/user/signup",
  emailVerify: "/otp/verify",
  resendOtp: "/otp/resend",
  addAccount: "/account/add",
  updateCurrency: "/add_and_update/currency",
  updateCurrencyList: "/currencyList",
  generate_referral_code: "/user/generate_referral_code",
  fcm_token: "/admin/fcm_token",
  user_list: "/admin/user/list",
  send_message:"/send/user/notification"
};
