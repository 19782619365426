import React from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { Apiurl } from "../../../services/apiRoute";
import { postMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";

const AddCurrency = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleAddCurrency = async (data) => {
    try {
      const response = await postMethod(`${Apiurl.currency}/add`, data);
      showAlert(response.data.message, "success");
      reset();
    } catch (error) {
      console.error("Error:", error);
      showAlert("Error while adding currency.Try again later!", "error");
    }
  };

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Currency</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddCurrency)}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Currency Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("name", {
                            required: "Currency Name is Required.",
                          })}
                          className="form-control"
                          name="name"
                          id="inputEmail3"
                          placeholder="Enter Currency Name"
                        />
                        {errors.name && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {errors.form && (
                      <p style={{ color: "red", fontSize: "13px" }}>
                        {errors.form}
                      </p>
                    )}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCurrency;
