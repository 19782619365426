import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { getMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import Pagination from "../../services/Pagination";
import debounce from "lodash/debounce";
import "../../css/sidebar.css";
import { showAlert } from "../../services/alert_service";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getUsers = async () => {
    setIsLoading(true);
    await getMethod(`${Apiurl.user_list}?page=${currentPage}&limit=10`)
      .then((res) => {
        setTotalPages(res.data.total_page);
        setUsers(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error" + error);
        showAlert("Error while fetching data", "error");
        setIsLoading(false);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getUsers();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>User List</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : users.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Sponsor Code</th>
                          <th>User Name</th>
                          <th>Email</th>
                          <th>Reference Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                {list?.sponsor_code != null
                                  ? list?.sponsor_code
                                  : "-"}
                              </td>
                              <td>
                                {list?.user_name != null
                                  ? list?.user_name
                                  : "-"}
                              </td>
                              <td>{list?.email != null ? list?.email : "-"}</td>
                              <td>
                                {list?.reference_code != null
                                  ? list?.reference_code
                                  : "-"}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
