import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '../components/pages/Sidebar';

const AccessControl = () => {

    const isLoggedIn = localStorage.getItem("token");
  
    return isLoggedIn ? <><Outlet/><Sidebar/></> : <Navigate to="/login" replace/>
  }  

export default AccessControl;
